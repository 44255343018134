
    import {Component, Vue} from 'vue-property-decorator';

    @Component<E404>({
        metaInfo()
        {
            return {
                title: 'Stránka nenalezena',
                titleTemplate: '%s | Půjčovna Haryků',
            };
        },
    })
    export default class E404 extends Vue
    {
    }
